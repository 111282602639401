import React from 'react';
import {VisitorIdentification, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import MetaData from './utils/MetaData';
import Placeholder from './core/Components/Placeholder';
import './styles/index.scss';

import smoothscrollPolyfill from 'smoothscroll-polyfill';
import smoothscrollAnchorPolyfill from 'smoothscroll-anchor-polyfill';	// invoked on import (does not need to be called)

/*
APP LAYOUT
This is where the app's HTML structure and root placeholders should be defined.

All routes share this root layout by default (this could be customized in RouteHandler),
but components added to inner placeholders are route-specific.
*/

// const Layout = ({route, sitecoreContext}) => {
class Layout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showMobileMenu: false,
			pageScrollable: true
		};
	}

	componentDidMount() {
		// Only continue if polyfills are actually needed
		if (!('scrollBehavior' in document.documentElement.style)) {
			console.log('triggering smooth scroll polyfill');
			smoothscrollPolyfill.polyfill();
		}
	}
  
  toggleMobileMenu = () => {
    this.setState(prevState => {
      return {
        showMobileMenu: !prevState.showMobileMenu
      }
    })
  }

  closeMobileMenu = () => {
    this.setState({showMobileMenu: false}) 
  }

	setPageScrollable = (value) => {
		this.setState({pageScrollable: value});
	}

  render() {
    const {showMobileMenu} = this.state;
    const {route, sitecoreContext} = this.props;
		const {fields} = route;
		const classNameNoScroll = (this.state.pageScrollable) ? '' : 'no-scroll';
		// const mainClassNames = `l-main ${classNameNoScroll}`;
		const mainClassNames = `l-main`;
	return (
		<React.Fragment>
			<MetaData />
			<VisitorIdentification />

			<div
				className={`l-layout l-one-column cf ${route.templateName} ${classNameNoScroll} ${
					fields.isProfessional.value ? 'professionals-page' : ''
				}`}
      >
				<main id="main" tabIndex="-1" className={mainClassNames}>
					<Placeholder
						name="global-header"
						rendering={route}
            isProfessional={fields.isProfessional.value}
            showMobileMenu={this.state.showMobileMenu}
            toggleMobileMenu={this.toggleMobileMenu}
            closeMobileMenu={this.closeMobileMenu}
						setPageScrollable={this.setPageScrollable}
					/>
					<article id="article" className={`article l-article ${showMobileMenu ? 'is-hidden' : ''}`}>
						<Placeholder
							name="header"
							rendering={route}
              isProfessional={fields.isProfessional.value}
              showMobileMenu={this.state.showMobileMenu}
						/>
						{(sitecoreContext.pageEditing || (!!route.placeholders.main && !!route.placeholders.main.length)) && (
							<div className="l-padding">
								<div className="l-content-column">
									<Placeholder
										name="main"
										rendering={route}
                    isProfessional={fields.isProfessional.value}
                    showMobileMenu={this.state.showMobileMenu}
									/>
								</div>
							</div>
						)}
						<Placeholder
							name="footer"
							rendering={route}
              isProfessional={fields.isProfessional.value}
              showMobileMenu={this.state.showMobileMenu}
						/>
					</article>
					<Placeholder
						name="global-footer"
						rendering={route}
            isProfessional={fields.isProfessional.value}
            showMobileMenu={this.state.showMobileMenu}
					/>
				</main>
			</div>
		</React.Fragment>
	)};
};

export default withSitecoreContext()(Layout);
