import React from 'react';
import Helmet from 'react-helmet';
import {withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';

const MetaData = ({sitecoreContext}) => {
	const {metadata} = sitecoreContext;
	const cardTitle = sitecoreContext?.route?.fields?.cardTitle?.value??''
	const cardDescription = sitecoreContext?.route?.fields?.cardDescription?.value??''
	const metaTitle = metadata.title || cardTitle
	const metaDescription = metadata.description || cardDescription
	const MetaDataObj = [
		{name: 'title', content: metaTitle},
		{name: 'description', content:metaDescription},
		{name: 'keywords', content: metadata.keywords},
		{
			'name': 'library',
			'class': 'swiftype',
			'data-type': 'string',
			'content': metadata.localeCode,
		},
		{
			'name': 'st_description',
			'class': 'swiftype',
			'data-type': 'string',
			'content': metaDescription,
		},
		{property: 'og:locale', content: metadata.localeCode},
		{property: 'og:type', content: 'article'},
		{property: 'og:title', content: metaTitle},
		{property: 'og:description', content: metaDescription},
		{property: 'og:url', content: metadata.url},
		{property: 'og:site_name', content: metadata.siteName},
		{property: 'og:updated_time', content: metadata.lastUpdated},
		{property: 'fb:app_id', content: metadata.facebookAppId},
		{name: 'twitter:description', content: metaDescription},
		{name: 'twitter:title', content: metaTitle},
		{name: 'twitter:site', content: metadata.siteName},
		{name: 'twitter:creator', content: metadata.twitterHandle},
	];

	if (!!metadata.featureImage) {
		MetaDataObj.push(
			{property: 'og:image', content: metadata.featureImage},
			{property: 'og:image:width', content: metadata.featuredWidth},
			{property: 'og:image:height', content: metadata.featuredHeight},
			{name: 'twitter:image', content: metadata.featureImage},
			{name: 'twitter:card', content: metadata.featureImage},
		);
	}

	// const metaLinks = [];
	// if (!!metadata.urls) {
	// 	metaLinks.push(<link key="canonical" rel="canonical" href={metadata.urls.canonical} />);
	// 	metadata.urls.alternate.forEach(alt => {
	// 		metaLinks.push(<link key={alt.lang} rel="alternate" href={alt.link} hrefLang={alt.lang} />);
	// 	});
	// }

	return (
		<Helmet meta={MetaDataObj.filter(item => item.content)}>
			<title>{ metaTitle || 'Page'}</title>
			{/* metaLinks */}
		</Helmet>
	);
};
export default withSitecoreContext()(MetaData);
