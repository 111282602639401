import React from 'react';
import Helmet from 'react-helmet';
import {isExperienceEditorActive, dataApi} from '@sitecore-jss/sitecore-jss-react';
import SitecoreContextFactory from './lib/SitecoreContextFactory';
import {dataFetcher} from './dataFetcher';
import config from './temp/config';
import Layout from './Layout';
import NotFound from './NotFound';
import resolveSite from './SiteResolver';

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

let ssrInitialState = null;

export default class RouteHandler extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			notFound: true,
			routeData: ssrInitialState, // null when client-side rendering
		};

		if (ssrInitialState && ssrInitialState.sitecore && ssrInitialState.sitecore.route) {
			// set the initial sitecore context data if we got SSR initial state
			SitecoreContextFactory.setSitecoreContext({
				route: ssrInitialState.sitecore.route,
				itemId: ssrInitialState.sitecore.route.itemId,
				...ssrInitialState.sitecore.context,
			});
		}

		// route data from react-router - if route was resolved, it's not a 404
		if (props.route !== null) {
			this.state.notFound = false;
		}

		// if we have an initial SSR state, and that state doesn't have a valid route data,
		// then this is a 404 route.
		if (ssrInitialState && (!ssrInitialState.sitecore || !ssrInitialState.sitecore.route)) {
			this.state.routeData = null;
			this.state.notFound = true;
		}

		// once we initialize the route handler, we've "used up" the SSR data,
		// if it existed, so we want to clear it now that it's in react state.
		// future route changes that might destroy/remount this component should ignore any SSR data.
		// EXCEPTION: Unless we are still SSR-ing. Because SSR can re-render the component twice
		// (once to find GraphQL queries that need to run, the second time to refresh the view with
		// GraphQL query results)
		// We test for SSR by checking for Node-specific process.env variable.
		if (typeof window !== 'undefined') {
			ssrInitialState = null;
		}

		this.componentIsMounted = false;
	}

	componentDidMount() {
		// if no existing routeData is present (from SSR), get Layout Service fetching the route data
		if (!this.state.routeData) {
			if(this.state.notFound){
				this.get404RouteData();
			}else {
			this.updateRouteData();
		}
		}

		this.componentIsMounted = true;
	}

	componentWillUnmount() {
		this.componentIsMounted = false;
	}

	/**
	 * Loads route data from Sitecore Layout Service into state.routeData
	 */
	updateRouteData() {
		let sitecoreRoutePath = this.props.route.match.params.sitecoreRoute || '/';
		if (!sitecoreRoutePath.startsWith('/')) {
			sitecoreRoutePath = `/${sitecoreRoutePath}`;
		}

		// get the route data for the new route
		getRouteData(sitecoreRoutePath).then(routeData => {
			if (routeData !== null && routeData.sitecore && routeData.sitecore.route) {
				// set the sitecore context data and push the new route
				SitecoreContextFactory.setSitecoreContext({
					route: routeData.sitecore.route,
					itemId: routeData.sitecore.route.itemId,
					...routeData.sitecore.context,
				});
				this.setState({routeData, notFound: false});
			} else {
				//Revert to the sitecore 404 page
				this.get404RouteData();
			}
		});
	}

	get404RouteData() {
		const siteObject = resolveSite(window.location.pathname)
		const siteString = '/404';
    
		getRouteData(siteString).then(routeData => {
			if (routeData !== null && routeData.sitecore && routeData.sitecore.route) {
				// set the sitecore context data and push the new route
				SitecoreContextFactory.setSitecoreContext({
					route: routeData.sitecore.route,
					itemId: routeData.sitecore.route.itemId,
					...routeData.sitecore.context,
			});
				this.setState({routeData, notFound: false});
			} else {
				this.setState({routeData, notFound: true});
		}
		});
	}

	componentDidUpdate(previousProps) {
		const existingRoute = previousProps.route.match.url;
		const newRoute = this.props.route.match.url;

		// don't change state (refetch route data) if the route has not changed
		if (existingRoute === newRoute) {
			return;
		}

		// if in experience editor - force reload instead of route data update
		// avoids confusing Sitecore's editing JS
		if (isExperienceEditorActive()) {
			window.location.assign(newRoute);
			return;
		}
		this.updateRouteData();
	}

	render() {
		const {notFound, routeData} = this.state;

		// Don't render anything if the route data is not fully loaded yet.
		// This is a good place for a "Loading" component, if one is needed.
		if (!routeData) {
			return null;
		}
		// no route data for the current route in Sitecore - show not found component.
		// Note: this is client-side only 404 handling. Server-side 404 handling is the responsibility
		// of the server being used (i.e. node-headless-ssr-proxy and Sitecore intergrated rendering know how to send 404 status codes).
		if (notFound) {
			return (
				<div>
					<Helmet>
						<title>Page not found</title>
					</Helmet>
					<NotFound context={routeData.sitecore && routeData.sitecore.context} />
				</div>
			);
		}



		// Render the app's root structural layout
		return <Layout route={routeData.sitecore.route} />;
	}
}

/**
 * Sets the initial state provided by server-side rendering.
 * Setting this state will bypass initial route data fetch calls.
 * @param {object} ssrState
 */
export function setServerSideRenderingState(ssrState) {
	ssrInitialState = ssrState;
}

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route Route path to get data for (e.g. /about)
 */
function getRouteData(route) {

	// Get custom site object to resolve site context and language.
	const siteObject = resolveSite(window.location.pathname);
  
  var hostfor404 = config.sitecoreApiHost;
  
  if (route === "/404") {
    	hostfor404 = hostfor404.replace("delivery.", "");
  }
  
	const fetchOptions = {
		layoutServiceConfig: {host: hostfor404},
		querystringParams: {sc_lang: siteObject.lang, sc_site: siteObject.siteName, sc_apikey: config.sitecoreApiKey},
		fetcher: dataFetcher,
	};

	return dataApi.fetchRouteData(route, fetchOptions).catch(error => {
		if (error.response && error.response.status === 404 && error.response.data) {
			return error.response.data;
		}

		console.error('Route data fetch error', error, error.response);

		return null;
	});
}
